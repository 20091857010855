<!--
 * @Descripttion: 
 * @version: 
 * @Author: Mr. Xu
 * @Date: 2022-08-09 16:00:00
 * @LastEditors: Mr. Xu
 * @LastEditTime: 2022-08-10 18:39:40
-->
<template>
    <div class="myDiv">
        <el-dialog
            title="增加任务"
            :visible.sync="centerDialogVisible"
            width="600px"
            center
            @closed="closed"
            :close-on-click-modal="false"
        >
            <div>
                <el-form
                    :model="ruleForm"
                    :rules="rules"
                    ref="ruleForm"
                    label-width="100px"
                    class="demo-ruleForm"
                >
                    <el-form-item label="手机号：" prop="phoneNum">
                        <el-input
                            placeholder="请输入手机号"
                            style="width: 300px"
                            v-model="ruleForm.phoneNum"
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item label="录入类型：" prop="model">
                        <el-select
                            v-model="ruleForm.model"
                            placeholder="请选择录入类型"
                            style="width: 300px"
                        >
                            <el-option label="GPS" value="1"></el-option>
                            <el-option label="报警" value="2"></el-option>
                            <el-option label="全部" value="3"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="过期时间：" prop="deviceId">
                        <el-select
                            v-model="ruleForm.timeType"
                            placeholder="请选择过期时间类型"
                            style="width: 300px"
                        >
                            <el-option label="永不" value="1"></el-option>
                            <el-option label="手动选择" value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="选择时间：">
                        <el-date-picker
                            style="width: 300px"
                            v-model="ruleForm.expirationTime"
                            type="datetime"
                            placeholder="选择日期时间"
                        >
                        </el-date-picker>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer flooterMenu">
                <el-button @click="cancel" size="small" style="width: 80px"
                    >取 消</el-button
                >
                <el-button
                    type="primary"
                    @click="sendTask"
                    size="small"
                    style="width: 80px"
                    >确 定
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import dayjs from 'dayjs';
export default {
    components: {},
    props: {},
    data() {
        return {
            baseUrl: window.cfg.deviceInput,
            userInfo: JSON.parse(window.sessionStorage.userInfo),
            centerDialogVisible: false,
            ruleForm: {
                model: "1",
                phoneNum: "",
                timeType: "1",
                expirationTime: "",
            },
            rules: {
                phoneNum: [
                    {
                        required: true,
                        message: "手机号不能为空",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    watch: {},
    computed: {},
    methods: {
        showDialog() {
            this.centerDialogVisible = true;
        },
        cancel() {
            this.centerDialogVisible = false;
        },
        sendTask() {
            let { ruleForm,baseUrl,userInfo } = this;
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    let params = {
                        phone:ruleForm.phoneNum,
                        msgAllowType:ruleForm.model,
                        expireTime:""
                    }
                    if(ruleForm.timeType !="1"){
                        if(!ruleForm.expirationTime){
                            this.$message.warning("请选择时间")
                            return;
                        }
                        params.expireTime = new Date(ruleForm.expirationTime).getTime();
                    }
                    console.log(params)
                    $.ajax({
                        url: `${baseUrl}/manager/nacos/addPhone`,
                        type: "POST",
                        dataType: "JSON",
                        headers: {
                            authtoken: userInfo.token,
                        },
                        contentType: "application/json",
                        data: JSON.stringify(params),
                        success: (res) => {
                            if(res.data){
                                this.$message.success("添加成功");
                                this.centerDialogVisible = false;
                            }else{
                                this.$message.error("添加失败");
                            }
                        },
                        error: (err) => {
                        },
                    });
                }
            });
        },
        closed() {
            this.$refs.ruleForm.resetFields();
            this.ruleForm = {
                model: "1",
                phoneNum: "",
                timeType: "1",
                expirationTime: "",
            };
        },
    },
    created() {},
    mounted() {},
};
</script>
<style lang="scss" scoped>
.myDiv {
}
</style>