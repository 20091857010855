<!--
 * @Descripttion: 
 * @version: 
 * @Author: Mr. Xu
 * @Date: 2022-08-09 14:23:52
 * @LastEditors: Mr. Xu
 * @LastEditTime: 2022-08-11 10:20:39
-->
<template>
    <div id="cutPhoto">
        <div class="curPhotoBody">
            <div class="goodsSearch">
                <el-form>
                    <el-form-item label="设备手机号：">
                        <el-input
                            v-model="form.phone"
                            style="width: 180px"
                            size="small"
                            clearable
                            placeholder="请输入手机号"
                        ></el-input>
                        <el-button
                            size="small"
                            class="goodsSearchBtn"
                            type="primary"
                            @click="researchData()"
                            >查询信息</el-button
                        >
                        <el-button
                            size="small"
                            class="goodsSearchBtn"
                            type="primary"
                            @click="addDevice()"
                            >添加设备</el-button
                        >
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="taskDetail">
            <el-descriptions
                class="margin-top"
                title=""
                :column="3"
                border
            >
                <template slot="extra">
                    <!-- <el-button type="primary" size="small">操作</el-button> -->
                </template>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-phone-outline"></i>
                        手机号
                    </template>
                    {{ detail.name }}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-document-remove"></i>
                        类型
                    </template>
                    {{ detail.msgAllowType }}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-timer"></i>
                        过期时间
                    </template>
                    {{ detail.expireTime }}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-s-check"></i>
                        操作人
                    </template>
                    {{ detail.createUser }}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-watch-1"></i>
                        操作时间
                    </template>
                    {{ detail.createTime }}
                </el-descriptions-item>
            </el-descriptions>
        </div>
        <Dialogdevice ref="deviceDialog"></Dialogdevice>
    </div>
</template>

<script>
import Dialogdevice from "./deviceDialog.vue";
import dayjs from "dayjs"
export default {
    components: { Dialogdevice },
    props: {},
    data() {
        return {
            baseUrl:window.cfg.deviceInput,
            userInfo: JSON.parse(window.sessionStorage.userInfo),
            form: {
                phone: "",
            },
            optionObj:{
                "1":"GPS",
                "2":"报警",
                "3":"全部"
            },
            detail: {},
        };
    },
    watch: {},
    computed: {},
    methods: {
        researchData() {
            let { baseUrl,form,userInfo,optionObj } = this;
            let params = {
                name:form.phone
            }
            if(!form.phone) return;
            $.ajax({
                url: `${baseUrl}/manager/nacos/phoneQry`,
                type: "GET",
                dataType: "JSON",
                headers:{
                    authtoken:userInfo.token
                },
                contentType: "application/json",
                data:params,
                success: (res) => {
                    let {data} = res;
                    if(data){
                        data.msgAllowType = optionObj[data.msgAllowType+""];
                        data.expireTime =data.expireTime? dayjs(data.expireTime).format('YYYY-MM-DD HH:mm:ss'):"永久";  // 初始化日期时间
                        data.createTime = dayjs(data.createTime).format('YYYY-MM-DD HH:mm:ss');  // 初始化日期时间
                        this.detail = data;
                    }else{
                        this.detail = {};
                        this.$message.warning("暂无数据")
                    }
                },
                error: (err) => {
                    console.log(999999999);
                },
            });
        },
        addDevice() {
            this.$refs.deviceDialog.showDialog()
        },
    },
    created() {
        if(!this.userInfo){
            this.$router.push({
                name:"Login"
            })
        }
    },
    mounted() {},
};
</script>
<style lang="scss" scoped>
.goodsSearch ::v-deep {
    background: #fff;
    clear: both;
    border-radius: 4px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 10px 0px;
    .el-radio-button__inner {
        border: none;
        width: 88px;
        padding: 10px 10px;
    }
    .el-form-item {
        display: inline-block;
        margin: 0;
        margin-left: 20px;
        .el-form-item__content {
            display: inline-block;
        }
    }
    .goodsSearchBtn {
        width: 80px;
        margin-left: 20px;
    }
}
.taskDetail {
    margin-top: 20px;
}
</style>